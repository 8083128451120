import React, { useEffect, useRef } from 'react';
import './NewsSection.css';
import { Link } from 'react-router-dom';
import TrumpMarketImage from '../assets/stocks-hausse-obligations-baisse1.png'; // Importez l'image pour l'article 5
import HausseBitcoinImage1 from '../assets/hausse-bitcoin-renouveau-ou-mirage1.jpg';
import UkrenergoImage from '../assets/ukraine-ukrenergo-suspend-paiements.png'; // Importez l'image pour l'article 4

function NewsSection() {
  const newsItemsRef = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const options = {
      root: isMobile ? newsItemsRef.current : null,
      rootMargin: '0px',
      threshold: isMobile ? 0.8 : 0.1, // Déclenche l'animation lorsque 50% de la carte est visible sur mobile, 10% sur ordinateur
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!isMobile) {
            const newsCards = document.querySelectorAll('.news-card');
            newsCards.forEach((card, index) => {
              setTimeout(() => {
                card.classList.add('visible');
              }, index * 500); // 300ms entre chaque carte
            });
          } else {
            entry.target.classList.add('visible');
          }
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, options);

    const newsCards = document.querySelectorAll('.news-card');
    newsCards.forEach((card) => observer.observe(card));

    if (isMobile) {
      newsCards.forEach((card) => {
        card.classList.remove('visible'); // Assurez-vous que les cartes sont masquées par défaut sur mobile
      });
    }

    return () => {
      newsCards.forEach((card) => observer.unobserve(card));
    };
  }, []);

  return (
    <section className="news" ref={newsItemsRef}>
      <div className="title">
        <h2>Top News</h2>
      </div>
      <div className="news-items">
        <Link to="/articles/ukraine-ukrenergo-suspend-paiements" className="news-card no-decoration">
          <img src={UkrenergoImage} alt="Ukrenergo suspend ses paiments" className="image" />
          <p className="top-news-meta">11 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
          <h3 className="newsTitle">L'Ukraine : Ukrenergo suspend les paiements de sa dette verte de 825 millions de dollars en attente de restructuration</h3>
        </Link>
        <Link to="/articles/hausse-bitcoin-renouveau-ou-mirage" className="news-card no-decoration">
          <img src={HausseBitcoinImage1} alt="Hausse du Bitcoin" className="image" />
          <p className="top-news-meta">10 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
          <h3 className="newsTitle">Hausse du Bitcoin : Vers un renouveau ou simple mirage pour les cryptomonnaies ?</h3>
        </Link>
        <Link to="/articles/stocks-hausse-obligations-baisse" className="news-card no-decoration">
          <img src={TrumpMarketImage} alt="les marchés financiers réagissent à la réélection de Donald Trump" className="image" />
          <p className="top-news-meta">5 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
          <h3 className="newsTitle">Stocks en Hausse, Obligations en Baisse : Réaction des Marchés après la Victoire de Trump</h3>
        </Link>
      </div>
    </section>
  );
}

export default NewsSection;